import {
  Button,
  Checkbox,
  Text,
  FormControl,
  FormLabel,
  Center,
  Input,
  Box,
  Stack,
  InputGroup,
  InputRightElement,
  useToast,
  Flex,
  Image,
} from "@chakra-ui/react";
import { useContext, useRef, useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { BsArrowRightShort } from "react-icons/bs";
import AuthContext from "../Utils/auth";
import { login } from "../Utils/api";
import { useNavigate, useParams } from "react-router-dom";

const Login = () => {
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const authCtx = useContext(AuthContext);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const handleChange = (event, setStateFunction) => {
    const { type, checked, value } = event.target;
    setStateFunction(type === "checkbox" ? checked : value);
  };

  const getLogin = async () => {
    setLoading(true);
    try {
      const loginRes = await login({
        username: email,
        password,
      });

      handleLoginResponse(loginRes);
    } catch (error) {
      handleLoginError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoginResponse = (loginRes) => {
    if (loginRes.status === "success") {
      authCtx.login(
        loginRes.data.token,
        loginRes.data.email,
        loginRes.data.name
      );
      window.location = "/";
    } else {
      console.log("login failed due to wrong credentials");
      toast({
        title: "Login Failed.",
        //desc non field to be changes to only string
        description: "Wrong Credentials",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleLoginError = (error) => {
    console.log("Error during login(Api call):", error);
    toast({
      title: "Login failed.",
      description: "An unexpected error occurred during login.",
      status: "error",
      position: "top-right",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Stack
      p={5}
      spacing={4}
      w={"95vw"}
      maxW={"xl"}
      style={{ borderWidth: "1px", borderRadius: "10px" }}
      border="1px solid black"
    >
      <Center>
        <Image
          src="/breakLogo.png"
          width="auto"
          height="6vh"
          objectFit="contain"
        />
      </Center>
      <Box>
        <Text fontSize={"24px"} fontWeight={600} letterSpacing={"-0.76px"}>
          Log In
        </Text>
        <Text fontSize={"14px"} color={"#717171"}>
          Let's get started with your Brain Pattern Mapping.
        </Text>
      </Box>
      <Stack spacing={4}>
        <FormControl isRequired id="email">
          <FormLabel fontSize={"16px"}>Email Address</FormLabel>
          <Input
            size="md"
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(event) => handleChange(event, setemail)}
          />
        </FormControl>
        <FormControl isRequired id="password">
          <Flex justifyContent="space-between" alignItems="center" mb="8px">
            <FormLabel fontSize="16px" mb="0">
              Enter Password
            </FormLabel>

            <Text
              onClick={() => {
                navigate("/crfb52mor8hv/forgot-password");
              }}
              as={"span"}
              width="fit-content"
              cursor="pointer"
              fontSize="14px"
              color="gray.400"
              _hover={{ color: "gray.600" }}
            >
              Forgot Password?
            </Text>
          </Flex>
          <InputGroup>
            <Input
              size="md"
              type={show ? "text" : "password"}
              placeholder="At least 8 characters long"
              value={password}
              onChange={(event) => handleChange(event, setPassword)}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
      </Stack>

      <Stack spacing={4}>
        <Checkbox
          m={"6px 0 6px 0"}
          letterSpacing={"-0.14px"}
          fontSize={"16px"}
          onChange={(event) => handleChange(event, setIsChecked)}
        >
          I agree to the
          <Text as={"b"} color={"black"}>
            {" "}
            Terms{" "}
          </Text>
          and
          <Text as={"b"} color={"black"}>
            {" "}
            Privacy Policy
          </Text>
          .
        </Checkbox>
        <Button
          size="md"
          isLoading={loading}
          onClick={getLogin}
          bg="black"
          color="white"
          rightIcon={<BsArrowRightShort />}
          mt="20px"
          _hover={{
            bg: "gray",
          }}
          _disabled={{
            bg: "black",
            cursor: "not-allowed",
          }}
          isDisabled={!email || !password || !isChecked}
        >
          Login to your account
        </Button>
      </Stack>
      <Flex gap="4px">
        <Text>Don't have an account ?</Text>
        <Text
          onClick={() => {
            navigate("/crfb52mor8hv");
          }}
          cursor="pointer"
          as={"span"}
          color={"black"}
          fontWeight="600"
        >
          Signup here.
        </Text>
      </Flex>
    </Stack>
  );
};

export default Login;
