import React, { useContext, useState, useEffect } from "react";
import { Heading, Text, Flex, Image, Button, Link } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import AuthContext from "../Utils/auth";
import "../index.css";
import CalendlyWidget from "../components/CalendlyWidget";
const LandingNew = () => {
  const authCtx = useContext(AuthContext);
  const user = authCtx.name
    ? capitalizeFirstLetter(authCtx.name)
    : authCtx.name;
  const location = useLocation();
  const { parameter, staticData } = location.state || {};
  function capitalizeFirstLetter(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map((word) => {
      return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
    });
    return capitalizedWords.join(" ");
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  return (
    <>
      <Flex
        height="100vh"
        justifyContent="center"
        flexDir="column"
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
      >
        <Flex
          flexDir="column"
          m="auto"
          alignItems="center"
          width={["90vw", "90vw", "90vw", "1200px", "1200px"]}
        >
          {(() => {
            switch (parameter) {
              case "report":
                return (
                  <>
                    <Flex
                      justifyContent="flex-start"
                      width={["85%", "85%", "85%", "650px", "650px"]}
                      mb="20px"
                    >
                      <Image
                        src="breakLogo.png"
                        alt="Logo"
                        width={["150px", "150px", "200px", "200px", "200px"]}
                      />
                    </Flex>
                    <Heading
                      fontFamily="Montserrat"
                      color="#FF2222"
                      fontWeight="500"
                      width={["85%", "85%", "85%", "650px", "650px"]}
                      textAlign="left"
                      fontSize={["24px", "24px", "24px", "32px", "32px"]}
                    >
                      Welcome back,{" "}
                      <Text as="span" fontWeight="600">
                        {user}.
                      </Text>
                    </Heading>

                    <Text
                      color="black"
                      textAlign="left"
                      mt="20px"
                      fontSize={["18px", "18px", "18px", "20px", "20px"]}
                      width={["85%", "85%", "85%", "650px", "650px"]}
                      fontFamily="Montserrat"
                      fontWeight="400"
                    >
                      Thank you for taking the Brain Pattern Mapping assessment.
                      We have received your responses and someone will be in
                      touch with you shortly.
                    </Text>
                  </>
                );
              case "start":
                return (
                  <>
                    <Flex
                      justifyContent="flex-start"
                      width={["85%", "85%", "85%", "650px", "650px"]}
                      mb="20px"
                    >
                      <Image
                        src="breakLogo.png"
                        alt="Logo"
                        width={["150px", "150px", "200px", "200px", "200px"]}
                      />
                    </Flex>
                    <Heading
                      fontFamily="Montserrat"
                      color="#FF2222"
                      fontWeight="500"
                      width={["85%", "85%", "85%", "650px", "650px"]}
                      textAlign="left"
                      fontSize={["24px", "24px", "24px", "32px", "32px"]}
                    >
                      Welcome,{" "}
                      <Text as="span" fontWeight="600">
                        {user}.
                      </Text>
                    </Heading>
                    <Text
                      color="black"
                      textAlign="left"
                      mt="20px"
                      fontSize={["18px", "18px", "18px", "20px", "20px"]}
                      width={["85%", "85%", "85%", "650px", "650px"]}
                      fontFamily="Montserrat"
                      fontWeight="400"
                    >
                      Brain Pattern Mapping takes{" "}
                      <u>approximately 30 minutes</u> to complete from start to
                      finish. To get started with your assessment, please press
                      the button below.
                    </Text>
                    <Flex
                      mt="40px"
                      mb="40px"
                      gap="12px"
                      width={["85%", "85%", "85%", "650px", "650px"]}
                    >
                      <a
                        href="/diagnostic"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          bg="#3B51C3"
                          width="240px"
                          fontWeight="700"
                          fontSize={["12px", "12px", "12px", "16px", "16px"]}
                          m="auto"
                          as="a"
                          href="/diagnostic"
                          target="_blank"
                          _hover={{
                            bg: "#4A62E0",
                          }}
                          color="white"
                          fontFamily="Montserrat"
                        >
                          START DIAGNOSTIC
                        </Button>
                      </a>
                    </Flex>
                  </>
                );
              case "progress":
                return (
                  <>
                    <Flex
                      justifyContent="flex-start"
                      width={["85%", "85%", "85%", "650px", "650px"]}
                      mb="20px"
                    >
                      <Image
                        src="breakLogo.png"
                        alt="Logo"
                        width={["150px", "150px", "200px", "200px", "200px"]}
                      />
                    </Flex>

                    <Heading
                      fontFamily="Montserrat"
                      color="#FF2222"
                      fontWeight="500"
                      width={["85%", "85%", "85%", "650px", "650px"]}
                      textAlign="left"
                      fontSize={["24px", "24px", "24px", "32px", "32px"]}
                    >
                      Welcome back,{" "}
                      <Text as="span" fontWeight="600">
                        {user}.
                      </Text>
                    </Heading>
                    <Text
                      color="black"
                      textAlign="left"
                      mt="20px"
                      fontSize={["18px", "18px", "18px", "20px", "20px"]}
                      width={["85%", "85%", "85%", "650px", "650px"]}
                      fontFamily="Montserrat"
                      fontWeight="400"
                    >
                      Brain Pattern Mapping takes{" "}
                      <u>approximately 30 minutes</u> to complete from start to
                      finish. To get started with your assessment, please press
                      the button below.
                    </Text>
                    <Flex
                      mt="40px"
                      mb="40px"
                      gap="12px"
                      width={["85%", "85%", "85%", "650px", "650px"]}
                    >
                      <a
                        href="/diagnostic"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          bg="#3B51C3"
                          width="240px"
                          fontWeight="700"
                          fontSize={["12px", "12px", "12px", "16px", "16px"]}
                          m="auto"
                          as="a"
                          _hover={{
                            bg: "#4A62E0",
                          }}
                          color="white"
                          fontFamily="Montserrat"
                        >
                          RESUME DIAGNOSTIC
                        </Button>
                      </a>
                    </Flex>
                  </>
                );
            }
          })()}
        </Flex>

        <Flex
          width={["85%", "85%", "85%", "650px", "650px"]}
          marginX="auto"
          justifyContent="space-between"
          py="20px"
          color="#8B8B8B"
          textDecoration="underline"
          fontSize={["12px", "12px", "12px", "14px", "14px"]}
        >
          <Flex gap="40px">
            <Link to="#">Terms and Conditions</Link>
            <Link to="#">Privacy Policy</Link>
          </Flex>
          <Flex>
            <Text cursor="pointer" onClick={() => authCtx.logout()} to="#">
              Logout
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default LandingNew;
