import React, { useState } from "react";
import { Flex, Text, Button } from "@chakra-ui/react";

const RadioCardTimeline = (props) => {
  return (
    <Flex
      p="5px 10px"
      cursor="pointer"
      m="0 7px 5px 0px"
      width={["100%", "100%", "48%", "48%", "48%"]}
      minHeight="80px"
      height="fit-content"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      fontWeight="400"
      bg={props.selected === true ? "yellow.300" : "white"}
      onClick={() => {
        props.setSelected(props.value);
      }}
      border="1px"
      borderRadius="5px"
      borderColor="gray.200"
    >
      {props.label}
    </Flex>
  );
};

const TimelineQuestionnaire = (props) => {
  const [responseObject, setResponseObject] = useState({});
  const updateResponse = (questionId, option) => {
    const updatedResponse = { ...responseObject };
    updatedResponse[questionId.toString()] = option ? 1 : 0;
    setResponseObject(updatedResponse);
  };

  const allQuestionsAnswered = () => {
    return Object.keys(responseObject).length === props.questionData.length;
  };

  return (
    <>
      <Flex
        flexDir="column"
        padding={{ md: "40px", base: "40px" }}
        m="auto"
        width={{ base: "100%", md: "1200px" }}
      >
        {props.questionData?.map((item, key) => (
          <>
            <Text
              fontSize={{ base: "24px", md: "24px" }}
              mt={{ md: "10px", base: "1vh" }}
              fontWeight={"900"}
            >
              {item.question_text}
            </Text>
            <Text mt="5px" fontSize="16px" color="gray.700" fontStyle="italic">
              Please select one option.
            </Text>
            <Flex flexDir="row" wrap="wrap" m="20px 0">
              <RadioCardTimeline
                value={1}
                // selected={selectedTime}
                selected={responseObject[String(item.id)] === 1 || false}
                setSelected={() => {
                  updateResponse(item.id.toString(), true);
                }}
                label={"Yes"}
              ></RadioCardTimeline>
              <RadioCardTimeline
                value={0}
                selected={responseObject[String(item.id)] === 0 || false}
                setSelected={() => updateResponse(String(item.id), false)}
                allQuestionsAnswered
                label={"No"}
              ></RadioCardTimeline>
            </Flex>
          </>
        ))}

        <Button
          bg="black"
          mb={{ base: "4vh", md: "0vh" }}
          color="white"
          borderRadius={"9px"}
          isDisabled={!allQuestionsAnswered()}
          width="150px"
          onClick={() => {
            props.setShow(false);
            props.submitTimelineAnswer(responseObject);
          }}
        >
          Next Page
        </Button>
      </Flex>
    </>
  );
};

export default TimelineQuestionnaire;
