import React, { useState } from "react";
import { Flex, Checkbox, Text, Button } from "@chakra-ui/react";

const MultiSelectQues = (props) => {
  console.log(props);
  const [answer, setAnswer] = useState([null]);

  const handleCheckboxChange = (optionId) => {
    const updatedAnswer = [...answer];

    if (updatedAnswer.includes(optionId)) {
      const index = updatedAnswer.indexOf(optionId);
      updatedAnswer.splice(index, 1);
    } else {
      updatedAnswer.push(optionId);
    }

    setAnswer(updatedAnswer);
  };

  function ScrollTop() {
    window.scrollTo(0, 0);
    return null;
  }

  return (
    <>
      <Flex
        flexDir="column"
        width={{ base: "450px", md: "800px" }}
        padding="40px"
        m="auto"
        key={props?.questionData.question_text}
      >
        <Text
          fontSize={{ base: "24px", md: "28px" }}
          fontWeight={"700"}
          mt="10px"
        >
          {props?.questionData.question_text}
        </Text>
        <Flex flexDir="column" m="20px 0">
          {props.questionData.options.map((option) => (
            <Checkbox
              key={option.id}
              className="custom-checkbox"
              borderRadius="full"
              m="5px 0"
              value={option.id}
              isChecked={answer.includes(option.id)}
              onChange={() => handleCheckboxChange(option.id)}
            >
              <Text fontSize={"16px"} color="gray.700">
                {option.option_text}
              </Text>
            </Checkbox>
          ))}
        </Flex>
        <Flex mt={5} justifyContent={"space-between"} mb={"5vh"}>
          <Button
            isDisabled={answer.length === 1 && answer[0] === null}
            onClick={() => {
              props.setShow(false); // Trigger fade-out before changing question
              setTimeout(() => {
                props.submitAnswerMulti(answer.join(",").substring(1));
              }, 500);
              ScrollTop();
            }}
            bg="black"
            color="white"
            borderRadius={"9px"}
            width="150px"
          >
            Next Question
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default MultiSelectQues;
