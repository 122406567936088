import {
  Text,
  Flex,
  Image,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  extendTheme,
  ChakraProvider,
  Heading,
  Button,
} from "@chakra-ui/react";
import NavbarInside from "../components/NavBarInside";
import Footer from "../components/Footer";
import Section1 from "../DiagnosticReport/Section1";
import Section2 from "../DiagnosticReport/Section2";
import Section3 from "../DiagnosticReport/Section3";
import Section4 from "../DiagnosticReport/Section4";
import Section5 from "../DiagnosticReport/Section5";
import Section6 from "../DiagnosticReport/Section6";
import Section7 from "../DiagnosticReport/Section7";
import Section8 from "../DiagnosticReport/Section8";
import CalendlyWidget from "../components/CalendlyWidget";
import { useState, useEffect, useContext } from "react";
import { getModelResponse, reportPageStaticDetails } from "../Utils/api";
import AuthContext from "../Utils/auth";
const sections = [
  // { title: "OVERVIEW", color: "#E48733" },
  // { title: "UNDERSTANDING YOUR BRAIN PATTERN", color: "#1B84FF" },
  // { title: "BRAIN PATTERN CONSTELLATIONS", color: "#FF5139" },
  // { title: "SOURCE BELIEF PATTERNS", color: "#1B84FF" },
  // { title: "EMOTIONAL ADDICTION CYCLE", color: "#FF973A" },
  // { title: "TIMELINE IMBALANCE", color: "#FF973A" },
  // { title: "WHAT TO EXPECT", color: "#A81BFF" },
  // { title: "OVERALL RECOMMENDATIONS", color: "#FFD600" },
];

const renderTabs = (orientation) => (
  <Tabs
    variant="unstyled"
    orientation={orientation}
    className={`resp-tabs${orientation === "horizontal" ? "-hidden" : ""}`}
  >
    <TabList>
      {sections.map((section, index) => (
        <Tab
          key={index}
          justifyContent="flex-start"
          _selected={{ color: "white", bg: section.color }}
          width={orientation === "vertical" ? "17vw" : "45vw"}
          height="12vh"
          mb={3}
          borderRadius="10px"
        >
          <Flex flexDir="column" alignItems="flex-start">
            <Text fontSize="16px">SECTION {index + 1}</Text>
            <Text fontSize="16px" fontWeight="800" textAlign="left">
              {section.title}
            </Text>
          </Flex>
        </Tab>
      ))}
    </TabList>
    <TabPanels>
      {sections.map((_, index) => (
        <TabPanel key={index}>
          {/* {index === 0 && <Section1 />} */}
          {/* {index === 1 && <Section2 />}
          {index === 2 && <Section3 />}
          {index === 3 && <Section4 />}
          {index === 4 && <Section5 />}
          {index === 5 && <Section6 />}
          {index === 6 && <Section7 />}
          {index === 7 && <Section8 />} */}
        </TabPanel>
      ))}
    </TabPanels>
  </Tabs>
);

const customBreakpoints = {
  base: "0px",
  md: "1200px",
};

const theme = extendTheme({
  breakpoints: customBreakpoints,
});

export default function Report() {
  const [res, setRes] = useState(null);
  const [staticData, setStaticData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const authCtx = useContext(AuthContext);

  const fetchData = async () => {
    try {
      setLoading(true);

      const [modelResponse, staticResponse] = await Promise.allSettled([
        getModelResponse(authCtx.email),
        reportPageStaticDetails(),
      ]);

      if (
        modelResponse.status === "fulfilled" &&
        modelResponse.value.status === "success"
      ) {
        setRes(modelResponse.value);
      }

      if (
        staticResponse.status === "fulfilled" &&
        staticResponse.value.status === "success"
      ) {
        setStaticData(staticResponse.value);
      } else if (
        staticResponse.status === "rejected" ||
        staticResponse.value.status !== "success"
      ) {
        setError("Failed to fetch the page details");
      }
    } catch (error) {
      setError("Please complete the diagnostics first.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <Flex minHeight="100vh" justifyContent="center" alignItems="center">
        <div className="loader"></div>
      </Flex>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <NavbarInside />
      <Flex style={{ minHeight: "100vh", flexDirection: "column" }}>
        <Image src="/report.png" width="100vw" />
        <Flex
          width={{ base: "100%", md: "1200px" }}
          justifyContent="center"
          flexDir="column"
          m="auto"
        >
          {error ? (
            <Center flexDir="column" height="50vh">
              <Heading
                fontFamily="Montserrat"
                textAlign="center"
                fontWeight="900"
                fontSize={["22px", "22px", "22px", "28px", "28px"]}
                width="100%"
                mt="40px"
                color="red.500"
                py="40px"
              >
                {error}
              </Heading>

              <Button
                onClick={() => {
                  window.location = "/diagnostic";
                }}
                bg="yellow"
                width="300px"
                fontWeight="900"
                padding="10px 45px"
                border="1px solid black"
              >
                RESUME DIAGNOSTIC
              </Button>
            </Center>
          ) : (
            <>
              {res && <Section1 res={res} />}
              <Heading
                fontFamily="Montserrat"
                textAlign="center"
                fontWeight="900"
                fontSize={["22px", "22px", "22px", "28px", "28px"]}
                width="100%"
                mt="40px"
              >
                BOOK A SESSION FOR YOUR BRAIN PATTERN MAP
              </Heading>

              <CalendlyWidget url={staticData.calendly} />
            </>
          )}
          <Flex mb="50px" />
        </Flex>
        <Footer style={{ marginTop: "auto" }} />
      </Flex>
    </ChakraProvider>
  );
}
