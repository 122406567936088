import React, { useState } from "react";
import { Flex, Center, Text, Textarea, Button } from "@chakra-ui/react";
const FreeQues = (props) => {
  const [textarea1Value, setTextarea1Value] = useState("");
  const [textarea2Value, setTextarea2Value] = useState("");
  const [textarea3Value, setTextarea3Value] = useState("");
  const [textarea1Error, setTextarea1Error] = useState(false);
  const [textarea2Error, setTextarea2Error] = useState(false);
  const [textarea3Error, setTextarea3Error] = useState(false);
  const [textarea1Words, setTextarea1Words] = useState(0);
  const [textarea2Words, setTextarea2Words] = useState(0);
  const [textarea3Words, setTextarea3Words] = useState(0);

  const checkWordCount = () => {
    const hasError1 = textarea1Words < 10;
    const hasError2 = textarea2Words < 10;
    const hasError3 = textarea3Words < 10;

    setTextarea1Error(hasError1);
    setTextarea2Error(hasError2);
    setTextarea3Error(hasError3);

    if (!hasError1 && !hasError2 && !hasError3) {
      const answerArea = `${textarea1Value};${textarea2Value};${textarea3Value}`;
      props.submitAnswerFree(answerArea);
      ScrollTop();
    }
  };

  function ScrollTop() {
    window.scrollTo(0, 0);
  }

  const handleTextareaChange = (e, setter, setWordCount) => {
    const { value } = e.target;
    setter(value);
    const wordCount = value.trim().split(/\s+/).length;
    setWordCount(wordCount);
  };

  const handleButtonAction = () => {
    checkWordCount();
  };
  return (
    <>
      <Flex
        flexDir="column"
        padding="40px"
        m="auto"
        key={props?.questionData.question_text}
        width={{ base: "350px", md: "800px" }}
      >
        <Center>
          <Flex
            flexDir={{ base: "column", md: "row" }}
            width={{ base: "90vw", md: "90vw" }}
            justifyContent={"space-evenly"}
          >
            <Flex
              flexDir="column"
              width={{ base: "90vw", md: "30vw" }}
              height={{ base: "55vh", md: "62vh" }}
              mt="1vh"
              mb={"2vh"}
              justifyContent={"space-between"}
            >
              <Flex
                flexDir="column"
                justifyContent={"flex-start"}
                width={{ base: "90vw", md: "25vw" }}
              >
                <Text fontWeight={"600"} fontSize={"24px"}>
                  ORIGIN
                </Text>
                <Text>
                  What repetitive experiences did you have in your life as an
                  early child prior to age 5? These can be things that describe
                  your childhood environment, the behaviors your parents
                  exhibited, how much or how little you trusted your parents,
                  their level of patience or warmth and even parental fighting
                  or communication habits.
                </Text>
              </Flex>
              <Flex justifyContent={"flex-start"} flexDir={"column"}>
                <Textarea
                  height={"27vh"}
                  value={textarea1Value}
                  onChange={(e) =>
                    handleTextareaChange(
                      e,
                      setTextarea1Value,
                      setTextarea1Words
                    )
                  }
                  width={{ base: "90vw", md: "28vw" }}
                  resize={"none"}
                  placeholder="Write an answer"
                  style={{
                    borderWidth: "0.5px",
                    borderColor: textarea1Value.borderColor || "black",
                    borderRadius: "13px",
                  }}
                />

                {textarea1Error && (
                  <Text color="red" fontSize="sm">
                    Please provide an answer of at least ten words.
                  </Text>
                )}
              </Flex>
            </Flex>
            <Flex
              width={{ base: "90vw", md: "30vw" }}
              flexDir="column"
              height={{ base: "55vh", md: "62vh" }}
              mt={{ md: "1vh", base: "10vh" }}
              mb={"2vh"}
              justifyContent={"space-between"}
            >
              <Flex
                flexDir="column"
                justifyContent={"flex-start"}
                width={{ base: "90vw", md: "28vw" }}
              >
                <Text fontWeight={"600"} fontSize={"24px"}>
                  PROTECTIVE
                </Text>
                <Text>
                  Every human being has a protective response that is unique.
                  Can you describe your behaviour, voice tone, body language,
                  and decision-making tendencies when you feel you are in an
                  unpredictable or unsteady situation? This section should
                  include roughly the experiences and behaviors most commonly
                  exhibited between ages 5-9. Despite the steps you take to
                  protect yourself, you find that you still can’t escape the
                  pain or suffering.
                </Text>
              </Flex>
              <Flex justifyContent={"flex-start"} flexDir={"column"}>
                <Textarea
                  height={"27vh"}
                  value={textarea2Value}
                  onChange={(e) =>
                    handleTextareaChange(
                      e,
                      setTextarea2Value,
                      setTextarea2Words
                    )
                  }
                  width={{ base: "90vw", md: "28vw" }}
                  resize={"none"}
                  placeholder="Write an answer"
                  style={{
                    borderWidth: "0.5px",
                    borderColor: textarea2Value.borderColor || "black",
                    borderRadius: "13px",
                  }}
                />

                {textarea2Error && (
                  <Text color="red" fontSize="sm">
                    Please provide an answer of at least ten words.
                  </Text>
                )}
              </Flex>
            </Flex>
            <Flex
              width={{ base: "100%", md: "30vw" }}
              flexDir="column"
              height={{ base: "55vh", md: "62vh" }}
              mt={{ md: "1vh", base: "14vh" }}
              mb={"2vh"}
              justifyContent={"space-between"}
            >
              <Flex
                flexDir="column"
                justifyContent={"flex-start"}
                width={{ base: "90vw", md: "28vw" }}
              >
                <Text fontWeight={"600"} fontSize={"24px"}>
                  ESCALATING
                </Text>
                <Text>
                  Can you describe your behaviour, voice tone, body language,
                  decision-making tendencies when you’ve given up or found a new
                  level of escalation? This section should includes the
                  escalating behaviors that begin to surface around 10-11 and
                  carry on through teen years.
                </Text>
              </Flex>
              <Flex justifyContent={"flex-start"} flexDir={"column"}>
                <Textarea
                  height={"27vh"}
                  value={textarea3Value}
                  onChange={(e) =>
                    handleTextareaChange(
                      e,
                      setTextarea3Value,
                      setTextarea3Words
                    )
                  }
                  width={{ base: "90vw", md: "27vw" }}
                  resize={"none"}
                  mt={{ base: "-4vh", md: "0vh" }}
                  placeholder="Write an answer"
                  style={{
                    borderWidth: "0.5px",
                    borderColor: textarea3Value.borderColor || "black",
                    borderRadius: "13px",
                  }}
                />

                {textarea3Error && (
                  <Text color="red" fontSize="sm">
                    Please provide an answer of at least ten words.
                  </Text>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Center>
        <Flex justifyContent={"flex-end"} width={{ base: "80vw", md: "65vw" }}>
          <Button
            bg="black"
            color="white"
            borderRadius={"20px"}
            width="150px"
            mb="30vh"
            mt={"5vh"}
            mr={{ base: "0vw", md: "0vw" }}
            onClick={() => {
              handleButtonAction();
            }}
          >
            Next Question
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default FreeQues;
