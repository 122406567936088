import React from "react";
import MultiSelectQues from "../components/MultiSelectQues";
import SingleSelectQues from "../components/SingleSelectQues";
import FreeFormQues from "../components/FreeFormQues";
import NumberQues from "../components/NumberQues";

const RenderQuestion = ({
  diagnosticData,
  authCtx,
  setShow,
  submitAnswerFreeFrom,
  setAnswerArea,
  setAnswer2,
  submitAnswerMulti,
  answer,
  fadeIn,
  submitAnswerNumber,
  selected,
  setSelected,
  setFadeIn,
  submitAnswerSingle,
  setAnswerText,
}) => {
  switch (diagnosticData.question_type) {
    case "MULTI_SELECT":
      return (
        <MultiSelectQues
          submitAnswerMulti={submitAnswerMulti}
          answer={answer}
          setShow={setShow}
          setAnswer2={setAnswer2}
          questionData={diagnosticData}
          clientId={authCtx.email}
          progress={1}
        />
      );
    case "SINGLE_SELECT":
      return (
        <SingleSelectQues
          questionData={diagnosticData}
          clientId={11}
          progress={1}
          selected={selected}
          setSelected={setSelected}
          setFadeIn={setFadeIn}
          setShow={setShow}
          submitAnswerSingle={submitAnswerSingle}
        />
      );
    case "TEXT":
      return (
        <FreeFormQues
          setAnswerArea={setAnswerArea}
          submitAnswerFreeFrom={submitAnswerFreeFrom}
          setShow={setShow}
          questionData={diagnosticData}
          progress={1}
          clientId={11}
        />
      );
    case "NUMBER":
      return (
        <NumberQues
          fadeIn={fadeIn}
          setAnswerText={setAnswerText}
          submitAnswerNumber={submitAnswerNumber}
          setShow={setShow}
          questionData={diagnosticData}
          clientId={11}
          progress={1}
        />
      );
    default:
      return null;
  }
};

export default RenderQuestion;
