import { Flex, Stack } from "@chakra-ui/react";
import ResetPassword from "../components/ResetPasswordComponent";

const ResetPasswordPage = () => {
  return (
    <Stack h="100vh" direction={{ base: "column", md: "row" }}>
      <Flex flex={1} alignItems="center" justify="center">
        <ResetPassword />
      </Flex>
    </Stack>
  );
};

export default ResetPasswordPage;
