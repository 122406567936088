import React, { useState } from "react";

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
  name: "",
  role: "",
  email:""
});

export const AuthContextProvider = (props) => {
  // user data
  const initialName = localStorage.getItem("name");
  const initialRole = localStorage.getItem("role");
  const [name, setName] = useState(initialName);
  const [role, setRole] = useState(initialRole);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("isLoggedIn"));


  // login token
  const initialToken = localStorage.getItem("token");
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [email, setEmail] = useState(localStorage.getItem("email") || "");

  // user login status
  const userIsLoggedIn = !!token;

  // function to log user in
  const loginHandler = (token,email,name="") => {
    // setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", "true");
    setToken(token);
    setEmail(email)
    setName(name)
    localStorage.setItem("email", email);
    localStorage.setItem("token", token);
    localStorage.setItem("name", name);
  };

  // update user name
  const updateUserInfo = (name) => {
    setName(name);
    localStorage.setItem("name", name);
  };
  const updateRole = (role) => {
    setRole(role);
    localStorage.setItem("role", role);
  };

  // funtion to log user out
  const logoutHandler = () => {
    setToken(null);
    localStorage.clear();
  };

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    name: name,
    role: role,
    login: loginHandler,
    logout: logoutHandler,
    updateUserInfo: updateUserInfo,
    updateRole: updateRole,
    email: email
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
