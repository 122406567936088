import React, { useState } from "react";
import { Flex, Text, Button } from "@chakra-ui/react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import DraggableBox from "./Last/DraggableBox";
import { AiOutlineSwap } from "react-icons/ai";

import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

const DragFinalQues = (props) => {
  const [numbersString, setNumbersString] = useState(props.numbersString);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      props.setBoxes((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        setNumbersString(
          arrayMove(items, oldIndex, newIndex)
            .map((item) => item.number)
            .join(",")
        );
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
  function handleSwap(pairIndex, pairIndex2) {
    const updatedObjects = [...props.boxes];

    const temp = updatedObjects[pairIndex];
    updatedObjects[pairIndex] = updatedObjects[pairIndex2];
    updatedObjects[pairIndex2] = temp;

    props.setBoxes(updatedObjects);
    setNumbersString(updatedObjects.map((item) => item.number).join(","));
  }
  return (
    <>
      <Flex
        flexDir="column"
        padding={{ md: "40px", base: "15px" }}
        m="auto"
        width={{ base: "100%", md: "1200px" }}
      >
        <Text
          fontSize={{ base: "24px", md: "28px" }}
          mt="10px"
          fontWeight={"900"}
          mb="1vh"
        >
          Which best describes the order of your behaviour when faced with a
          challenging or uncertain situation?
        </Text>
        <Text fontSize="16px" color="gray.700" fontStyle="italic" mb="2vh">
          Please arrange these statements in the order they most frequently
          occur in your day-to-day life
        </Text>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <Flex className="dnd">
            <SortableContext
              items={props.boxes}
              strategy={horizontalListSortingStrategy}
            >
              {props.boxes.map((id, index) => (
                <>
                  <DraggableBox key={id} id={id} text={id.statement} />
                  {(index === 0 || index === 1) && (
                    <Flex
                      alignItems={"center"}
                      mr="-4"
                      ml="-5"
                      className="swap-icon"
                    >
                      <Button
                        style={{
                          position: "relative",
                          borderWidth: "1px",
                          borderRadius: "100px",
                        }}
                        width={"2vw"}
                        p={"2.5"}
                        _hover={{
                          borderWidth: "1px",
                          borderColor: "yellow.300",
                          transition: "0.3s",
                          borderRadius: "50%",
                        }}
                        bg="#fff"
                        onClick={() => {
                          handleSwap(index, index + 1);
                        }}
                      >
                        <AiOutlineSwap size={"25px"} />
                      </Button>
                    </Flex>
                  )}
                </>
              ))}
            </SortableContext>
          </Flex>
        </DndContext>

        <Flex
          flexDir="row"
          mt="1vh"
          mb={"2vh"}
          justifyContent={"space-between"}
        >
          <Flex ml={"2"}>
            <Text fontSize={"18px"} fontWeight={"800"}>
              Early Stages
            </Text>
          </Flex>
          <Flex mr="3">
            <Text fontSize={"18px"} fontWeight={"800"}>
              Late Stages
            </Text>
          </Flex>
        </Flex>

        <Button
          bg="black"
          color="white"
          borderRadius={"9px"}
          width="150px"
          mb="30vh"
          // onClick={props.submitTimelineOrder(
          //   numbersString,
          //   "timeline_ordering"
          // )}
          onClick={() => {
            props.submitTimelineOrder(numbersString, "timeline_ordering");
          }}
        >
          Submit
        </Button>
      </Flex>
    </>
  );
};

export default DragFinalQues;
