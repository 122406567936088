import { Flex, Stack } from "@chakra-ui/react";
import Login from "../components/Login";

const LoginPage = () => {
  return (
    <Stack h="100vh" direction={{ base: "column", md: "row" }}>
      <Flex flex={1} alignItems="center" justify="center">
        <Login />
      </Flex>
    </Stack>
  );
};

export default LoginPage;
