import {
  Flex,
  Box,
  Heading,
  Text,
  Input,
  Textarea,
  Button,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { postQuery } from "../Utils/api";
import Footer from "../components/Footer";

const HelpDeskNew = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [issue, setIssue] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await postQuery(email, name, issue);
      setMessage("Your query has been registered.");
    } catch (error) {
      console.error("Error registering query:", error);
      setMessage("Error registering your query. Please try again.");
    }
  };
  return (
    <>
      <Flex minH="93vh">
        <Flex
          flexDir="column"
          m="auto"
          p="20px"
          width={["90vw", "90vw", "90vw", "700px", "700px"]}
          bg="white"
          boxShadow="0px 0px 8px rgba(0, 0, 0, 0.1)"
          borderRadius="8px"
          mt="20px"
          borderTop="10px solid #a17f00"
        >
          {!message && (
            <>
              <Heading m="auto" as="h2" mb="20px">
                Help Desk — Brain Pattern Mapping
              </Heading>
              <form onSubmit={handleSubmit}>
                <Box
                  mb="20px"
                  p="20px"
                  bg="#fff"
                  border="1px solid #ccc"
                  borderRadius="8px"
                >
                  <Box as="label" htmlFor="name" style={{ fontSize: "18px" }}>
                    Please share your name.
                    <span style={{ color: "red" }}>*</span>
                  </Box>
                  <Input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    size="lg"
                    variant="flushed"
                    placeholder="Your answer"
                  />
                </Box>
                <Box
                  mb="20px"
                  p="20px"
                  border="1px solid #ccc"
                  bg="#fff"
                  borderRadius="8px"
                >
                  <Box as="label" htmlFor="name" style={{ fontSize: "18px" }}>
                    Please share your email address.
                    <span style={{ color: "red" }}>*</span>
                  </Box>
                  <Input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    size="lg"
                    variant="flushed"
                    placeholder="Your answer"
                  />
                </Box>

                <Box
                  mb="20px"
                  border="1px solid #ccc"
                  p="20px"
                  bg="#fff"
                  borderRadius="8px"
                >
                  <Box as="label" htmlFor="name" style={{ fontSize: "18px" }}>
                    Please describe the issue that you are facing.
                    <span style={{ color: "red" }}>*</span>
                  </Box>
                  <Textarea
                    id="issue"
                    value={issue}
                    onChange={(e) => setIssue(e.target.value)}
                    required
                    size="lg"
                    variant="flushed"
                    placeholder="Your answer"
                  />
                </Box>
                <Button type="submit" color="white" bg="#a17f00" size="md">
                  Submit
                </Button>
              </form>
            </>
          )}
          {message && (
            <Box textAlign="center" fontWeight="bold" fontSize="xl">
              {message}
            </Box>
          )}
        </Flex>
      </Flex>
      <Footer />
    </>
  );
};

export default HelpDeskNew;
