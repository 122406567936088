import {
  Checkbox,
  Flex,
  Heading,
  Text,
  Center,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { BiRadioCircle } from "react-icons/bi";
import AuthContext from "../Utils/auth";
import { getModelResponse } from "../Utils/api";
const Section1 = ({ res }) => {
  const [answer, setAnswer] = useState([]);
  const [loading, setLoading] = useState([]);
  const [report, setReport] = useState([]);
  const [eacO, setEacO] = useState();
  const [eacE, setEacE] = useState();
  const [eacP, setEacP] = useState();
  const [osb, setOsb] = useState();
  const [asb, setAsb] = useState();
  const [timelineData, setTimelineData] = useState();
  const [covert, setCovert] = useState();
  const [selfTrust, setSelfTrust] = useState("");
  const authCtx = useContext(AuthContext);

  const [timelinePrimary, setTimelinePrimary] = useState("");
  const [timelineSecondary, setTimelineSecondary] = useState("");
  const [timelineTertiary1, setTimelineTertiary1] = useState("");
  const [timelineTertiary2, setTimelineTertiary2] = useState("");

  const setAnswer2 = (arr) => {
    setAnswer(arr);
  };
  function capitalizeFirstLetter(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map((word) => {
      return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
    });
    return capitalizedWords.join(" ");
  }

  useEffect(() => {
    setReport(res.data);
    setEacO(res.data.data.eac_orgin);
    setEacE(res.data.data.eac_esc);
    setEacP(res.data.data.eac_protect);
    setOsb(res.data.data.osb);
    setAsb(res.data.data.asb);
    setCovert(res.data.data.interaction_style);
    setSelfTrust(res.data.data.self_trust);
    setTimelinePrimary(res.data.timeline_numbers.primary);
    setTimelineSecondary(res.data.timeline_numbers.secondary);
    setTimelineTertiary1(res.data.timeline_numbers.tertiary_1);
    setTimelineTertiary2(res.data.timeline_numbers.tertiary_2);
    setLoading(false);
  }, []);

  return (
    <>
      {/* <Flex flexDir="column" width="100%">
        <Flex justifyContent="center" p="20px" textAlign="center">
          <Text
            my={{ md: "5vh", base: "2vh" }}
            fontSize={"1.7rem"}
            fontWeight={"800"}
          >
            {" "}
            YOUR BRAIN PATTERN MAP IS COMPLETE.
          </Text>
        </Flex>

        <Flex justifyContent="center">
          {loading ? (
            <>
              <Flex alignItems="center" h="50vh">
                <Spinner />
              </Flex>
            </>
          ) : (
            <>
              <Flex
                width={{ base: "85vw", md: "60%" }}
                flexDir={"column"}
                ml={{ base: "0", md: "0" }}
              >
                <Flex>
                  <Flex flexDir={"column"}>
                    <Text fontSize={"16px"} fontWeight={"500"} color="black">
                      NAME
                    </Text>
                    <Text fontSize={"22px"} fontWeight={"800"}>
                      {" "}
                      {authCtx.name
                        ? capitalizeFirstLetter(authCtx.name)
                        : authCtx.name}
                    </Text>
                  </Flex>
                </Flex>
                <Flex mt={10}>
                  <Flex flexDir={"column"}>
                    <Flex>
                      <Text fontSize={"16px"} fontWeight={"500"} color="black">
                        EMOTIONAL ADDICTION CYCLE
                      </Text>
                    </Flex>

                    <Flex
                      flexDir={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      color="#FF002D"
                    >
                      {" "}
                      <Flex className="resp-svg">
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.7072 13.6252C15.5481 15.0669 14.8053 16.3328 13.7203 17.1812C13.4153 17.9111 12.8704 18.5157 12.1839 18.8963C11.9895 19.6508 11.3045 20.2082 10.4892 20.2082C9.67401 20.2082 8.98897 19.6508 8.79455 18.8963C8.10821 18.5158 7.56326 17.9112 7.25837 17.1815C6.17694 16.336 5.43536 15.076 5.27277 13.6403C4.31819 14.3647 2.95694 14.3609 2.00024 13.5582C0.889673 12.6263 0.744816 10.9705 1.67669 9.86L5.25829 5.59162C5.48718 2.90343 7.7417 0.792969 10.4892 0.792969C13.2269 0.792969 15.4752 2.88849 15.7175 5.56306L19.3231 9.86C20.2549 10.9705 20.11 12.6263 18.9995 13.5582C18.0362 14.3665 16.6627 14.3647 15.7072 13.6252ZM13.6721 11.293L12.2036 9.54299H8.79612L7.32767 11.293H13.6721ZM15.7392 11.0339L16.6419 12.1097C16.9525 12.4799 17.5045 12.5282 17.8746 12.2176C18.2448 11.907 18.2931 11.355 17.9825 10.9849L15.7392 8.31136V11.0339ZM13.9892 7.79297V6.04297C13.9892 4.10997 12.4221 2.54297 10.4892 2.54297C8.55615 2.54297 6.98915 4.10997 6.98915 6.04297V7.79297H13.9892ZM4.35785 12.1097L5.23915 11.0594V8.33694L3.01727 10.9849C2.70665 11.355 2.75494 11.907 3.12512 12.2176C3.49531 12.5282 4.04722 12.4799 4.35785 12.1097ZM10.4892 16.543C8.55615 16.543 6.98915 14.9759 6.98915 13.043H13.9892C13.9892 14.9759 12.4221 16.543 10.4892 16.543Z"
                            fill="#FF002D"
                          />
                        </svg>{" "}
                      </Flex>
                      <Flex>
                        <Text
                          fontSize={"22px"}
                          fontWeight={"800"}
                          // ml={{md:3, base:8}}
                          ml={0}
                          mr={3}
                        >
                          {" "}
                          {report ? capitalizeFirstLetter(eacO) : ""}
                        </Text>{" "}
                      </Flex>
                      <Flex
                        width={{ md: "63px", base: "20px" }}
                        height={"2px"}
                        bg="black"
                      />
                      <Flex className="resp-svg" ml={3} mr={3}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_303_445)">
                            <path
                              d="M11.0001 17.4173C9.22821 17.4173 7.62408 16.6991 6.46289 15.538L11.0001 11.0007V4.58398C14.544 4.58398 17.4168 7.45683 17.4168 11.0007C17.4168 14.5445 14.544 17.4173 11.0001 17.4173Z"
                              fill="#FF002D"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M21.0837 10.9993C21.0837 16.5682 16.5692 21.0827 11.0003 21.0827C5.43146 21.0827 0.916992 16.5682 0.916992 10.9993C0.916992 5.43048 5.43146 0.916016 11.0003 0.916016C16.5692 0.916016 21.0837 5.43048 21.0837 10.9993ZM19.2503 10.9993C19.2503 15.5557 15.5567 19.2493 11.0003 19.2493C6.44398 19.2493 2.75033 15.5557 2.75033 10.9993C2.75033 6.443 6.44398 2.74935 11.0003 2.74935C15.5567 2.74935 19.2503 6.443 19.2503 10.9993Z"
                              fill="#FF002D"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_303_445">
                              <rect width="22" height="22" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </Flex>
                      <Flex>
                        <Text
                          fontSize={"22px"}
                          fontWeight={"800"}
                          ml={{ md: -1, base: 2 }}
                          mr={3}
                          color="#FF002D"
                        >
                          {" "}
                          {report ? capitalizeFirstLetter(eacP) : ""}
                        </Text>{" "}
                      </Flex>
                      <Flex
                        width={{ md: "80px", base: "20px" }}
                        height={"2px"}
                        bg="black"
                      />
                      <Flex className="resp-svg" ml={2}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="#FF002D"
                        >
                          <path
                            d="M9 11C9.55228 11 10 10.5523 10 10C10 9.44772 9.55228 9 9 9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11Z"
                            fill="#FF002D"
                          />
                          <path
                            d="M9 15C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17H15C15.5523 17 16 16.5523 16 16C16 15.4477 15.5523 15 15 15H9Z"
                            fill="#FF002D"
                          />
                          <path
                            d="M16 10C16 10.5523 15.5523 11 15 11C14.4477 11 14 10.5523 14 10C14 9.44772 14.4477 9 15 9C15.5523 9 16 9.44772 16 10Z"
                            fill="#FF002D"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
                            fill="#FF002D"
                          />
                        </svg>
                      </Flex>
                      <Flex>
                        <Text
                          fontSize={"22px"}
                          fontWeight={"800"}
                          ml={3}
                          mr={3}
                        >
                          {" "}
                          {report ? capitalizeFirstLetter(eacE) : ""}
                        </Text>{" "}
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex mt={10} flexDir={{ base: "column", md: "row" }}>
                  <Flex flexDir={"column"}>
                    <Flex>
                      <Text fontSize={"16px"} fontWeight={"500"} color="black">
                        SOURCE BELIEF PATTERN
                      </Text>
                    </Flex>

                    <Flex
                      flexDir={"row"}
                      alignItems={"center"}
                      justifyContent="space-between"
                    >
                      {" "}
                      <Flex className="resp-svg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7Z"
                            fill="#2daefd"
                          />
                          <path
                            d="M16 15C16 14.4477 15.5523 14 15 14H9C8.44772 14 8 14.4477 8 15V21H6V15C6 13.3431 7.34315 12 9 12H15C16.6569 12 18 13.3431 18 15V21H16V15Z"
                            fill="#2daefd"
                          />
                        </svg>{" "}
                      </Flex>
                      <Flex>
                        <Text
                          fontSize={"22px"}
                          fontWeight={"800"}
                          // ml={3}
                          mr={3}
                          color="#2daefd"
                        >
                          {" "}
                          {report ? (
                            capitalizeFirstLetter(osb)
                          ) : (
                            <>loading...</>
                          )}
                        </Text>{" "}
                      </Flex>
                      <Flex
                        width={{ md: "93px", base: "50px" }}
                        height={"2px"}
                        bg="black"
                      />
                      <Flex className="resp-svg" ml={1} mr={3}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="34"
                          height="34"
                          viewBox="0 0 34 34"
                          fill="none"
                        >
                          <path
                            d="M25.4889 24.115L16.9723 9.88428L8.51074 24.115H25.4889Z"
                            fill="#2daefd"
                          />
                        </svg>
                      </Flex>
                      <Flex width={{ base: "90%", md: "20vw" }}>
                        <Text
                          fontSize={"22px"}
                          fontWeight={"800"}
                          ml={{ md: -1, base: 3 }}
                          mr={3}
                          whiteSpace={{ base: "", md: "nowrap" }}
                          color="#2daefd"
                          lineHeight="1"
                        >
                          {" "}
                          {report ? capitalizeFirstLetter(asb) : ""}
                        </Text>{" "}
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex
                  mt={10}
                  justifyContent="space-between"
                  flexDir={{ base: "column", md: "row" }}
                >
                  <Flex flexDir={"column"}>
                    <Text fontSize={"16px"} fontWeight={"500"} color="black">
                      SELF-TRUST
                    </Text>

                    <Text
                      fontSize={"22px"}
                      fontWeight={"800"}
                      mr={3}
                      color="#0AEE3D"
                    >
                      {report
                        ? selfTrust
                          ? capitalizeFirstLetter(selfTrust)
                          : "N/A"
                        : null}
                    </Text>
                  </Flex>

                  <Flex mt={{ base: "10", md: "0" }} flexDir={"column"}>
                    <Flex>
                      <Text fontSize={"16px"} fontWeight={"500"} color="black">
                        INTERACTION STYLE
                      </Text>
                    </Flex>

                    <Flex
                      flexDir={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      {" "}
                      <Flex>
                        <Text
                          fontSize={"22px"}
                          fontWeight={"800"}
                          mr={3}
                          color="#0AEE3D"
                        >
                          {" "}
                          {report ? (
                            capitalizeFirstLetter(covert)
                          ) : (
                            <>loading...</>
                          )}
                        </Text>{" "}
                      </Flex>
                    </Flex>
                  </Flex>

                  <Flex></Flex>
                  <Flex></Flex>
                  <Flex></Flex>
                </Flex>

                <Flex
                  justifyContent={"space-between"}
                  mt={5}
                  mb="5vh"
                  flexDir={{ base: "column", md: "row" }}
                >
                  <Flex mt={5} flexDir={"column"}>
                    <Text fontSize={"16px"} fontWeight={"500"} color="black">
                      PRIMARY
                    </Text>
                    <Text fontSize={"22px"} fontWeight={"800"} color="#e300ff">
                      {" "}
                      {timelinePrimary}
                    </Text>
                  </Flex>

                  <Flex mt={5} flexDir={"column"}>
                    <Text fontSize={"16px"} fontWeight={"500"} color="black">
                      SECONDARY
                    </Text>
                    <Text fontSize={"22px"} fontWeight={"800"} color="#e300ff">
                      {timelineSecondary}
                    </Text>
                  </Flex>
                  <Flex mt={5} flexDir={"column"}>
                    <Text fontSize={"16px"} fontWeight={"500"} color="black">
                      TERTIARY 1
                    </Text>
                    <Text fontSize={"22px"} fontWeight={"800"} color="#e300ff">
                      {timelineTertiary1}
                    </Text>
                  </Flex>
                  <Flex mt={5} flexDir={"column"}>
                    <Text fontSize={"16px"} fontWeight={"500"} color="black">
                      TERTIARY 2
                    </Text>
                    <Text fontSize={"22px"} fontWeight={"800"} color="#e300ff">
                      {timelineTertiary2}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </>
          )}
        </Flex>
      </Flex> */}
    </>
  );
};

export default Section1;
