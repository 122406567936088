import { Flex, Text, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <Flex
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      textAlign="center"
    >
      <Text fontSize="6xl" fontWeight="bold" mb="4">
        404
      </Text>
      <Text fontSize="2xl" fontWeight="bold" mb="2">
        Oops! Page not found.
      </Text>
      <Text fontSize="lg" mb="8">
        Please visit the <strong>Break Member Portal </strong>
        and use the link provided there.
      </Text>

      <Button
        bg="#FEED00"
        size="lg"
        color="black"
        textTransform="uppercase"
        fontWeight="900"
        borderRadius="20px"
        as="a"
        href="https://thebreakprogram.com/"
      >
        Back to Break Member Portal
      </Button>
    </Flex>
  );
};

export default Error;
