import React, { useRef } from "react";
import { Flex, Text } from "@chakra-ui/react";
// import React from 'react';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DraggableBox = ({ id, text, index, moveBox }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: id,
      
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Flex
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      p="5px 10px"
      cursor="pointer"
      m="0 7px 5px 0px"
      width={["100%", "100%", "48%", "48%", "48%"]}
      height={{base:"",md:"15vh"}}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      fontWeight="400"
      bg={"white"}
      fontSize={{ base: "14px", md: "16px" }}
      border="1px"
      _hover={{
        borderColor: "yellow.300",
        boxShadow: "lg",
        transition: "0.1s",
      }}
      borderRadius="5px"
      borderColor="gray.200"
      transition="opacity 0.3s ease"
    >
      {text}
    </Flex>
  );
};

export default DraggableBox;
