import { Flex, Text, Box } from "@chakra-ui/react";
import { useContext, useState } from "react";
import AuthContext from "../Utils/auth";

const Footer = (props) => {
  const authCtx = useContext(AuthContext);
  const isPublic = props.public !== undefined ? props.public : true;
  return (
    <Flex
      p="20px"
      flexDir={{ md: "row", base: "column" }}
      width="100%"
      justifyContent={{ md: "space-between", base: "center" }}
      alignItems="center"
      bg="black"
      position="sticky"
      height="100%"
      top="100%"
      color="white"
    >
      <Flex
        justifyContent="space-between"
        ml={{ md: "30px", base: 0 }}
        flexDir={{ md: "row", base: "column" }}
        alignItems="center"
      >
        <Text as="a" href="/help" mr={{ md: "20px", base: "0" }}>
          Help Desk
        </Text>
        {isPublic && (
          <Flex
            justifyContent="space-between"
            flexDir={{ md: "row", base: "column" }}
            alignItems="center"
          >
            <Text
              cursor="pointer"
              onClick={() => authCtx.logout()}
              m={{ md: "0 20px 0 0", base: "2px 0 0 0" }}
            >
              Logout
            </Text>

            <Text
              as="a"
              href="/crfb52mor8hv/reset-password"
              m={{ md: "0 20px 0 0", base: "2px 0 0 0" }}
            >
              Reset Password
            </Text>

            <Text
              as="a"
              target="_blank"
              href="/course-materials"
              m={{ md: "0 20px 0 0", base: "2px 0 0 0" }}
            >
              Course Materials
            </Text>
          </Flex>
        )}
      </Flex>

      <Flex
        flexDir={{ md: "row", base: "column" }}
        mx={{ md: "20px", base: "0px" }}
        alignItems="center"
      >
        <Text mt={{ base: "10px", md: "0px" }} mr={{ md: "20px", base: "0" }}>
          Break Method &#169; 2024
        </Text>
        <Text mt={{ base: "10px", md: "0px" }} mr={{ md: "20px", base: "0" }}>
          Powered By FJ
        </Text>
      </Flex>
    </Flex>
  );
};

export default Footer;
