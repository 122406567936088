import { Text, Flex } from "@chakra-ui/react";

const SectionBreak = (props) => {
  return (
    <>
      <Flex ml={"5vw"} mr={"5vw"} mt={"10vh"} mb="0vh">
        <Flex
          flexDir={{ base: "column", md: "row" }}
          justifyContent={"space-between"}
        >
          <Flex width={{ base: "95%", md: "60%" }} flexDir={"column"}>
            <Text
              width={{ base: "90vw", md: "90vw" }}
              mt={{ base: "-10vh", md: "0vh" }}
              fontSize={{ md: "24px", base: "24px" }}
              fontWeight={"800"}
            >
              {props.data.section_data[0].heading}
            </Text>
            <Text
              mt={{ base: "2vh", md: "4vh" }}
              fontSize={{ md: "18px", base: "16px" }}
              fontWeight={"500"}
              lineHeight={"200%"}
              letterSpacing={"-0.72px"}
            >
              {" "}
              {props.data.section_data[0].descripton}
            </Text>
          </Flex>
          <Flex alignItems={"center"} justifyContent={"center"}></Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default SectionBreak;
