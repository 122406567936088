import React, {useState} from "react";
import { Flex, Text, Button } from "@chakra-ui/react";

const RadioCard = (props) => {
  return (
    <Flex
      p="5px 10px"
      cursor="pointer"
      m="0 7px 5px 0px"
      width={["100%", "100%", "48%", "48%", "48%"]}
      minHeight="80px"
      height="fit-content"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      fontWeight="400"
      bg={props.value === props.selected ? "yellow.300" : "white"}
      onClick={() => {
        props.setSelected(props.value);
      }}
      border="1px"
      borderRadius="5px"
      borderColor="gray.200"
    >
      {props.label}
    </Flex>
  );
};

const SingleSelectQues = (props) => {
  const [selectedLocal, setSelectedLocal] = useState(null)
  return (
    <>
      <Flex
        flexDir="column"
        padding="40px"
        m="auto"
        key={props?.questionData.question_text}
        width={{ base: "400px", md: "800px" }}
      >
        <Text
          fontSize={{ base: "24px", md: "28px" }}
          mt="10px"
          fontWeight={"700"}
        >
          {props.questionData?.question_text}
        </Text>
        <Text mt="5px" fontSize="16px" color="gray.700" fontStyle="italic">
          Please select one option.
        </Text>
        <Flex flexDir="row" wrap="wrap" m="20px 0">
          {props.questionData.options.map((option, key) => (
            <RadioCard
              selected={selectedLocal}
              value={option.id}
              label={option.option_text}
              setSelected={setSelectedLocal}
            ></RadioCard>
          ))}
        </Flex>

        <Button
          bg="black"
          color="white"
          borderRadius={"9px"}
          width="150px"
          mb={"5vh"}
          isDisabled={selectedLocal === null ? true : false}
          onClick={() => {
            props.setFadeIn(false); // Trigger fade-out before changing question
            props.setShow(false);
            props.submitAnswerSingle(selectedLocal.toString());
          }}
        >
          Next Question
        </Button>
      </Flex>
    </>
  );
};

export default SingleSelectQues;
